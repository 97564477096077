<div class="app-body">
    <div class="row">
        <div class="col-xxl-12">
            <div class="card" style="margin-left: 1px;">
                <div class="card-body">
                    <div class="custom-tabs-container">
                        <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-link active" id="tab-ThoiTiet" data-bs-toggle="tab" href="#ThoiTiet"
                                    role="tab" aria-controls="ThoiTiet" aria-selected="true">
                                    <span class="badge bg-info" style="font-size: 16px;">Thời tiết</span>
                                </a>
                            </li>
                        </ul>
                        <div class="tab-content" id="customTabContent">
                            <div class="tab-pane fade show active" id="ThoiTiet" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-3 col-sm-12 col-12">
                                        <label class="form-label">Tỉnh thành [{{ThoiTietService.BaseParameter.DanhMucTinhThanhID}}]</label>
                                        <select class="form-select" name="ThoiTietService.BaseParameter.DanhMucTinhThanhID"
                                            [(ngModel)]="ThoiTietService.BaseParameter.DanhMucTinhThanhID">
                                            <option *ngFor="let item of DanhMucTinhThanhService.List;"
                                                [value]="item.ID">
                                                {{item.Name}} [{{item.ID}}]</option>
                                        </select>
                                    </div>
                                    <div class="col-lg-3 col-sm-12 col-12">
                                        <label class="form-label">Ghi nhận</label>
                                        <div class="col s12 m12 l2">
                                            <input
                                                [ngModel]="ThoiTietService.BaseParameter.NgayGhiNhan | date:'yyyy-MM-dd'"
                                                (ngModelChange)="DateNgayGhiNhan($event)"
                                                [ngModelOptions]="{ updateOn: 'blur' }" type="date" class="form-control"
                                                autocomplete="off">
                                        </div>
                                    </div>
                                    <div class="col-lg-5 col-sm-12 col-12">
                                        <label *ngIf="!NotificationService.IsMobile" class="form-label">.</label>
                                        <input [(ngModel)]="ThoiTietService.BaseParameter.SearchString"
                                            placeholder="Tìm ..." class="form-control" (keyup.enter)="ThoiTietSearch()">
                                    </div>
                                    <div class="col-lg-1 col-sm-12 col-12">
                                        <label class="form-label">.</label>
                                        <div>
                                            <a style="width: 100%;" class="btn btn-success" title="Tìm" (click)="ThoiTietSearch()"><i
                                                    class="bi bi-search"></i></a>&nbsp;&nbsp;&nbsp;

                                        </div>
                                    </div>
                                </div>
                                <div class="card mb-4 card-info">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">Danh sách
                                            <span *ngIf="ThoiTietService.List">({{ThoiTietService.List.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table *ngIf="!NotificationService.IsMobile" mat-table matSort
                                                    #ThoiTietSort="matSort" [dataSource]="ThoiTietService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            STT
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;"
                                                            style="text-align: center;">
                                                            {{ThoiTietPaginator.pageSize *
                                                            ThoiTietPaginator.pageIndex + i + 1}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="NgayGhiNhan">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Ghi
                                                            nhận
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.NgayGhiNhan | date:'MM/dd/yyyy'}} |
                                                            {{element.GioGhiNhan}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DanhMucTinhThanhName">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tỉnh thành
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <b>{{element.DanhMucTinhThanhName}}</b>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DanhMucQuanHuyenName">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Quận huyện
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <b>{{element.DanhMucQuanHuyenName}}</b>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DanhMucXaPhuongName">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Xã phường
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <b>{{element.DanhMucXaPhuongName}}</b>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="TongQuan">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tổng quan
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.TongQuan}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="NhietDo">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Nhiệt độ
                                                            môi trường
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">
                                                            {{element.NhietDo}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DoAm">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Độ ẩm môi
                                                            trường
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">
                                                            {{element.DoAm}} %
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="HuongGio">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Hướng gió
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">
                                                            {{element.HuongGio}}
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ThoiTietService.DisplayColumns001">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: ThoiTietService.DisplayColumns001;">
                                                    </tr>
                                                </table>
                                                <mat-paginator *ngIf="!NotificationService.IsMobile"
                                                    #ThoiTietPaginator="matPaginator" [pageSizeOptions]="[10,20,50,100]"
                                                    [pageSize]="6" [showFirstLastButtons]></mat-paginator>
                                                <table *ngIf="NotificationService.IsMobile" mat-table matSort
                                                    #ThoiTietSort="matSort" [dataSource]="ThoiTietService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Danh sách
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;">
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ThoiTietService.DisplayColumnsMobile">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: ThoiTietService.DisplayColumnsMobile;">
                                                    </tr>
                                                </table>
                                                <mat-paginator *ngIf="NotificationService.IsMobile"
                                                    #ThoiTietPaginator="matPaginator" [pageSizeOptions]="[10,20,50,100]"
                                                    [pageSize]="10000" [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="DanhMucTinhThanhService.IsShowLoading"></app-loading>