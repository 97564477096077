import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { DownloadService } from 'src/app/shared/Download.service';

import { DanhMucTinhThanh } from 'src/app/shared/DanhMucTinhThanh.model';
import { DanhMucTinhThanhService } from 'src/app/shared/DanhMucTinhThanh.service';

import { ThoiTiet } from 'src/app/shared/ThoiTiet.model';
import { ThoiTietService } from 'src/app/shared/ThoiTiet.service';

@Component({
  selector: 'app-thoi-tiet',
  templateUrl: './thoi-tiet.component.html',
  styleUrls: ['./thoi-tiet.component.css']
})
export class ThoiTietComponent implements OnInit {

  @ViewChild('ThoiTietSort') ThoiTietSort: MatSort;
  @ViewChild('ThoiTietPaginator') ThoiTietPaginator: MatPaginator;

  constructor(
    private dialog: MatDialog,
    public NotificationService: NotificationService,
    public DownloadService: DownloadService,

    public DanhMucTinhThanhService: DanhMucTinhThanhService,
    public ThoiTietService: ThoiTietService,
  ) { }

  ngOnInit(): void {
    this.DanhMucTinhThanhSearch();
  }
  DateNgayGhiNhan(value) {
    this.ThoiTietService.BaseParameter.NgayGhiNhan = new Date(value);
  }
  DanhMucTinhThanhSearch() {
    this.ThoiTietService.IsShowLoading = true;
    this.DanhMucTinhThanhService.BaseParameter.ParentID = environment.DanhMucQuocGiaIDVietNam;
    this.DanhMucTinhThanhService.GetByParentIDToListAsync().subscribe(
      res => {
        this.DanhMucTinhThanhService.List = (res as DanhMucTinhThanh[]).sort((a, b) => (a.Name > b.Name ? 1 : -1));              
        if (this.DanhMucTinhThanhService.List) {
          if (this.DanhMucTinhThanhService.List.length > 0) {
            this.ThoiTietService.BaseParameter.DanhMucTinhThanhID = this.DanhMucTinhThanhService.List[0].ID;
          }
        }
        this.ThoiTietService.IsShowLoading = false;
      },
      err => {
        this.ThoiTietService.IsShowLoading = false;
      }
    );
    this.DanhMucTinhThanhService.ComponentGetByParentIDToListAsync(this.ThoiTietService);
  }
  ThoiTietSearch() { 
    if (this.ThoiTietService.BaseParameter.SearchString.length > 0) {
      this.ThoiTietService.BaseParameter.SearchString = this.ThoiTietService.BaseParameter.SearchString.trim();
      if (this.ThoiTietService.DataSource) {
        this.ThoiTietService.DataSource.filter = this.ThoiTietService.BaseParameter.SearchString.toLowerCase();
      }
    }
    else {
      this.ThoiTietService.IsShowLoading = true;
      this.ThoiTietService.GetByDanhMucTinhThanhID_NgayGhiNhanToListAsync().subscribe(
        res => {
          this.ThoiTietService.List = (res as ThoiTiet[]).sort((a, b) => (a.ID > b.ID ? 1 : -1));
          this.ThoiTietService.DataSource = new MatTableDataSource(this.ThoiTietService.List);
          this.ThoiTietService.DataSource.sort = this.ThoiTietSort;
          this.ThoiTietService.DataSource.paginator = this.ThoiTietPaginator;
          this.ThoiTietService.IsShowLoading = false;
        },
        err => {
          this.ThoiTietService.IsShowLoading = false;
        }
      );
    }
  }
}
