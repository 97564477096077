import { Component, OnInit, Inject, ElementRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { DownloadService } from 'src/app/shared/Download.service';

import { ToChuc } from 'src/app/shared/ToChuc.model';
import { ToChucService } from 'src/app/shared/ToChuc.service';

import { DanhMucUngDung } from 'src/app/shared/DanhMucUngDung.model';
import { DanhMucUngDungService } from 'src/app/shared/DanhMucUngDung.service';
import { DanhMucQuocGia } from 'src/app/shared/DanhMucQuocGia.model';
import { DanhMucQuocGiaService } from 'src/app/shared/DanhMucQuocGia.service';
import { DanhMucTinhThanh } from 'src/app/shared/DanhMucTinhThanh.model';
import { DanhMucTinhThanhService } from 'src/app/shared/DanhMucTinhThanh.service';
import { DanhMucQuanHuyen } from 'src/app/shared/DanhMucQuanHuyen.model';
import { DanhMucQuanHuyenService } from 'src/app/shared/DanhMucQuanHuyen.service';
import { DanhMucXaPhuong } from 'src/app/shared/DanhMucXaPhuong.model';
import { DanhMucXaPhuongService } from 'src/app/shared/DanhMucXaPhuong.service';
import { DanhMucToChuc } from 'src/app/shared/DanhMucToChuc.model';
import { DanhMucToChucService } from 'src/app/shared/DanhMucToChuc.service';

import { TemPhieuKhoiTao } from 'src/app/shared/TemPhieuKhoiTao.model';
import { TemPhieuKhoiTaoService } from 'src/app/shared/TemPhieuKhoiTao.service';
import { TemPhieuKhoiTaoChiTiet } from 'src/app/shared/TemPhieuKhoiTaoChiTiet.model';
import { TemPhieuKhoiTaoChiTietService } from 'src/app/shared/TemPhieuKhoiTaoChiTiet.service';

import { TemPhieuKhoiTaoDetailToChucComponent } from '../tem-phieu-khoi-tao-detail-to-chuc/tem-phieu-khoi-tao-detail-to-chuc.component';

@Component({
  selector: 'app-to-chuc-detail',
  templateUrl: './to-chuc-detail.component.html',
  styleUrls: ['./to-chuc-detail.component.css']
})
export class ToChucDetailComponent implements OnInit {

  @ViewChild('TemPhieuKhoiTaoSort') TemPhieuKhoiTaoSort: MatSort;
  @ViewChild('TemPhieuKhoiTaoPaginator') TemPhieuKhoiTaoPaginator: MatPaginator;

  @ViewChild('TemPhieuKhoiTaoChiTietSort') TemPhieuKhoiTaoChiTietSort: MatSort;
  @ViewChild('TemPhieuKhoiTaoChiTietPaginator') TemPhieuKhoiTaoChiTietPaginator: MatPaginator;

  @ViewChild('TemPhieuKhoiTaoChiTietSortFilter') TemPhieuKhoiTaoChiTietSortFilter: MatSort;
  @ViewChild('TemPhieuKhoiTaoChiTietPaginatorFilter') TemPhieuKhoiTaoChiTietPaginatorFilter: MatPaginator;

  constructor(
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<ToChucDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,

    public NotificationService: NotificationService,
    public DownloadService: DownloadService,

    public ToChucService: ToChucService,

    public DanhMucUngDungService: DanhMucUngDungService,
    public DanhMucToChucService: DanhMucToChucService,
    public DanhMucQuocGiaService: DanhMucQuocGiaService,
    public DanhMucTinhThanhService: DanhMucTinhThanhService,
    public DanhMucQuanHuyenService: DanhMucQuanHuyenService,
    public DanhMucXaPhuongService: DanhMucXaPhuongService,

    public TemPhieuKhoiTaoService: TemPhieuKhoiTaoService,
    public TemPhieuKhoiTaoChiTietService: TemPhieuKhoiTaoChiTietService,


  ) { }

  ngOnInit(): void {
    this.ToChucSearch();
  }
  ToChucSearch() {
    this.ToChucService.IsShowLoading = true;
    this.ToChucService.BaseParameter.ID = this.ToChucService.FormData.ID;
    this.ToChucService.GetByIDAsync().subscribe(
      res => {
        this.ToChucService.FormData = res as ToChuc;
        this.ToChucService.FormData.DanhMucQuocGiaID = environment.DanhMucQuocGiaIDVietNam;
        this.DanhMucUngDungSearch();
        this.DanhMucToChucSearch();
        this.DanhMucQuocGiaSearch();
        this.TemPhieuKhoiTaoSearch();
        this.ToChucService.IsShowLoading = false;
      },
      err => {
        this.ToChucService.IsShowLoading = false;
      }
    );
  }
  DanhMucUngDungSearch() {
    this.DanhMucUngDungService.ComponentGetAllToListSortByNameAsync(this.ToChucService);
  }
  DanhMucToChucSearch() {
    this.DanhMucToChucService.ComponentGetAllToListSortByNameAsync(this.ToChucService);
  }
  DanhMucQuocGiaSearch() {
    if (this.DanhMucQuocGiaService.List) {
      if (this.DanhMucQuocGiaService.List.length == 0) {
        this.ToChucService.IsShowLoading = true;
        this.DanhMucQuocGiaService.GetAllToListAsync().subscribe(
          res => {
            this.DanhMucQuocGiaService.List = (res as DanhMucQuocGia[]).sort((a, b) => (a.Name > b.Name ? 1 : -1));
            this.DanhMucTinhThanhSearch();
            this.ToChucService.IsShowLoading = false;
          },
          err => {
            this.ToChucService.IsShowLoading = false;
          }
        );
      }
    }
  }
  DanhMucTinhThanhSearch() {

    this.ToChucService.IsShowLoading = true;
    this.DanhMucTinhThanhService.BaseParameter.ParentID = this.ToChucService.FormData.DanhMucQuocGiaID;
    this.DanhMucTinhThanhService.GetByParentIDToListAsync().subscribe(
      res => {
        this.DanhMucTinhThanhService.List = (res as DanhMucTinhThanh[]).sort((a, b) => (a.Name > b.Name ? 1 : -1));
        this.DanhMucQuanHuyenSearch();
        this.ToChucService.IsShowLoading = false;
      },
      err => {
        this.ToChucService.IsShowLoading = false;
      }
    );

  }
  DanhMucQuanHuyenSearch() {

    this.ToChucService.IsShowLoading = true;
    this.DanhMucQuanHuyenService.BaseParameter.ParentID = this.ToChucService.FormData.DanhMucTinhThanhID;
    this.DanhMucQuanHuyenService.GetByParentIDToListAsync().subscribe(
      res => {
        this.DanhMucQuanHuyenService.List = (res as DanhMucQuanHuyen[]).sort((a, b) => (a.Name > b.Name ? 1 : -1));
        this.DanhMucXaPhuongSearch();
        this.ToChucService.IsShowLoading = false;
      },
      err => {
        this.ToChucService.IsShowLoading = false;
      }
    );

  }
  DanhMucXaPhuongSearch() {
    this.ToChucService.IsShowLoading = true;
    this.DanhMucXaPhuongService.BaseParameter.ParentID = this.ToChucService.FormData.DanhMucQuanHuyenID;
    this.DanhMucXaPhuongService.GetByParentIDToListAsync().subscribe(
      res => {
        this.DanhMucXaPhuongService.List = (res as DanhMucXaPhuong[]).sort((a, b) => (a.Name > b.Name ? 1 : -1));
        this.ToChucService.IsShowLoading = false;
      },
      err => {
        this.ToChucService.IsShowLoading = false;
      }
    );
  }
  ToChucSave() {
    this.ToChucService.IsShowLoading = true;
    this.ToChucService.SaveAsync().subscribe(
      res => {
        this.ToChucService.FormData = res as ToChuc;
        this.NotificationService.warn(environment.SaveSuccess);
        this.ToChucService.IsShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.ToChucService.IsShowLoading = false;
      }
    );
  }
  TemPhieuKhoiTaoSearch() {
    this.ToChucService.IsShowLoading = true;
    this.TemPhieuKhoiTaoService.BaseParameter.ToChucID = this.ToChucService.FormData.ID;
    this.TemPhieuKhoiTaoService.GetByToChucIDToListAsync().subscribe(
      res => {
        this.TemPhieuKhoiTaoService.List = (res as TemPhieuKhoiTao[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.TemPhieuKhoiTaoService.DataSource = new MatTableDataSource(this.TemPhieuKhoiTaoService.List);
        this.TemPhieuKhoiTaoService.DataSource.sort = this.TemPhieuKhoiTaoSort;
        this.TemPhieuKhoiTaoService.DataSource.paginator = this.TemPhieuKhoiTaoPaginator;
        this.ToChucService.IsShowLoading = false;
      },
      err => {
        this.ToChucService.IsShowLoading = false;
      }
    );
  }
  TemPhieuKhoiTaoAdd(ID: number) {
    this.ToChucService.IsShowLoading = true;
    this.TemPhieuKhoiTaoService.BaseParameter.ID = ID;
    this.TemPhieuKhoiTaoService.GetByIDAsync().subscribe(
      res => {
        this.TemPhieuKhoiTaoService.FormData = res as TemPhieuKhoiTao;
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = environment.DialogConfigWidth;
        dialogConfig.data = { ID: ID };
        const dialog = this.dialog.open(TemPhieuKhoiTaoDetailToChucComponent, dialogConfig);
        dialog.afterClosed().subscribe(() => {
        });
        this.ToChucService.IsShowLoading = false;
      },
      err => {
        this.ToChucService.IsShowLoading = false;
      }
    );
  }

  TemPhieuKhoiTaoChiTietSearch() {
    if (this.TemPhieuKhoiTaoChiTietService.BaseParameter.SearchString.length > 0) {
      this.TemPhieuKhoiTaoChiTietService.BaseParameter.SearchString = this.TemPhieuKhoiTaoChiTietService.BaseParameter.SearchString.trim();
      if (this.TemPhieuKhoiTaoChiTietService.DataSource) {
        this.TemPhieuKhoiTaoChiTietService.DataSource.filter = this.TemPhieuKhoiTaoChiTietService.BaseParameter.SearchString.toLowerCase();
      }
    }
    else {
      this.ToChucService.IsShowLoading = true;
      this.TemPhieuKhoiTaoChiTietService.BaseParameter.ToChucID = this.ToChucService.FormData.ID;
      this.TemPhieuKhoiTaoChiTietService.BaseParameter.Active = false;
      this.TemPhieuKhoiTaoChiTietService.GetSQLByToChucID_ActiveToListAsync().subscribe(
        res => {
          this.TemPhieuKhoiTaoChiTietService.List = (res as TemPhieuKhoiTaoChiTiet[]).sort((a, b) => (a.ID > b.ID ? 1 : -1));
          this.TemPhieuKhoiTaoChiTietService.DataSource = new MatTableDataSource(this.TemPhieuKhoiTaoChiTietService.List);
          this.TemPhieuKhoiTaoChiTietService.DataSource.sort = this.TemPhieuKhoiTaoChiTietSort;
          this.TemPhieuKhoiTaoChiTietService.DataSource.paginator = this.TemPhieuKhoiTaoChiTietPaginator;
          this.ToChucService.IsShowLoading = false;
        },
        err => {
          this.ToChucService.IsShowLoading = false;
        }
      );
    }
  }
  TemPhieuKhoiTaoChiTietSearchFilter() {
    if (this.TemPhieuKhoiTaoChiTietService.BaseParameter.SearchString.length > 0) {
      this.TemPhieuKhoiTaoChiTietService.BaseParameter.SearchString = this.TemPhieuKhoiTaoChiTietService.BaseParameter.SearchString.trim();
      if (this.TemPhieuKhoiTaoChiTietService.DataSourceFilter) {
        this.TemPhieuKhoiTaoChiTietService.DataSourceFilter.filter = this.TemPhieuKhoiTaoChiTietService.BaseParameter.SearchString.toLowerCase();
      }
    }
    else {
      this.ToChucService.IsShowLoading = true;
      this.TemPhieuKhoiTaoChiTietService.BaseParameter.ToChucID = this.ToChucService.FormData.ID;
      this.TemPhieuKhoiTaoChiTietService.BaseParameter.Active = true;
      this.TemPhieuKhoiTaoChiTietService.GetSQLByToChucID_ActiveToListAsync().subscribe(
        res => {
          this.TemPhieuKhoiTaoChiTietService.ListFilter = (res as TemPhieuKhoiTaoChiTiet[]).sort((a, b) => (a.ID > b.ID ? 1 : -1));
          this.TemPhieuKhoiTaoChiTietService.DataSourceFilter = new MatTableDataSource(this.TemPhieuKhoiTaoChiTietService.ListFilter);
          this.TemPhieuKhoiTaoChiTietService.DataSourceFilter.sort = this.TemPhieuKhoiTaoChiTietSortFilter;
          this.TemPhieuKhoiTaoChiTietService.DataSourceFilter.paginator = this.TemPhieuKhoiTaoChiTietPaginatorFilter;
          this.ToChucService.IsShowLoading = false;
        },
        err => {
          this.ToChucService.IsShowLoading = false;
        }
      );
    }
  }

  TemPhieuKhoiTaoChiTietDownload() {
    this.ToChucService.IsShowLoading = true;
    this.DownloadService.BaseParameter.ToChucID = this.ToChucService.FormData.ID;
    this.DownloadService.BaseParameter.Active = false;
    this.DownloadService.ExportTemPhieuKhoiTaoChiTietByToChucID_ActiveToExcelAsync().subscribe(
      res => {
        window.open(res.toString(), "_blank");
        this.ToChucService.IsShowLoading = false;
      },
      err => {
        this.ToChucService.IsShowLoading = false;
      }
    );
  }
  TemPhieuKhoiTaoChiTietDownloadFilter() {
    this.ToChucService.IsShowLoading = true;
    this.DownloadService.BaseParameter.ToChucID = this.ToChucService.FormData.ID;
    this.DownloadService.BaseParameter.Active = true;
    this.DownloadService.ExportTemPhieuKhoiTaoChiTietByToChucID_ActiveToExcelAsync().subscribe(
      res => {
        window.open(res.toString(), "_blank");
        this.ToChucService.IsShowLoading = false;
      },
      err => {
        this.ToChucService.IsShowLoading = false;
      }
    );
  }

  Close() {
    this.dialogRef.close();
  }
}
