import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-thanh-vien-lich-su-truy-cap',
  templateUrl: './thanh-vien-lich-su-truy-cap.component.html',
  styleUrls: ['./thanh-vien-lich-su-truy-cap.component.css']
})
export class ThanhVienLichSuTruyCapComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
