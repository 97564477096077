<div class="app-body">
    <div class="row">
        <div class="col-xxl-12">
            <div class="card" style="margin-left: 1px;">
                <div class="card-body">
                    <div class="custom-tabs-container">
                        <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-link active" id="tab-TemPhieuKhoiTao" data-bs-toggle="tab"
                                    href="#TemPhieuKhoiTao" role="tab" aria-controls="TemPhieuKhoiTao"
                                    aria-selected="true">
                                    <span class="badge bg-warning" style="font-size: 16px;">Lô tem do Cơ quan quản lý cấp</span>
                                </a>
                            </li>
                        </ul>
                        <div class="tab-content" id="customTabContent">
                            <div class="tab-pane fade show active" id="TemPhieuKhoiTao" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-11 col-sm-12 col-12">
                                        <input [(ngModel)]="TemPhieuKhoiTaoService.BaseParameter.SearchString"
                                            placeholder="Tìm ..." class="form-control"
                                            (keyup.enter)="TemPhieuKhoiTaoSearch()">
                                    </div>
                                    <div class="col-lg-1 col-sm-12 col-12">
                                        <a style="width: 100%;" class="btn btn-success" title="Tìm"
                                            (click)="TemPhieuKhoiTaoSearch()"><i
                                                class="bi bi-search"></i></a>&nbsp;&nbsp;&nbsp;
                                    </div>
                                </div>
                                <div class="card mb-4 card-warning">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">Danh sách
                                            <span
                                                *ngIf="TemPhieuKhoiTaoService.List">({{TemPhieuKhoiTaoService.List.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table *ngIf="!NotificationService.IsMobile" mat-table matSort
                                                    #TemPhieuKhoiTaoSort="matSort"
                                                    [dataSource]="TemPhieuKhoiTaoService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            STT
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;"
                                                            style="text-align: center;">
                                                            <div *ngIf="TemPhieuKhoiTaoPaginator">
                                                                {{TemPhieuKhoiTaoPaginator.pageSize *
                                                                TemPhieuKhoiTaoPaginator.pageIndex + i + 1}}
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ID">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>ID
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <a style="cursor: pointer;" title="{{element.Code}}"
                                                                class="link-primary"
                                                                (click)="TemPhieuKhoiTaoAdd(element.ID)"><b>{{element.ID}}</b></a>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="GhiNhan">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Ghi nhận
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <a style="cursor: pointer;" title="{{element.Code}}"
                                                                class="link-primary"
                                                                (click)="TemPhieuKhoiTaoAdd(element.ID)"><b>{{element.GhiNhan
                                                                    |
                                                                    date:'dd/MM/yyyy'}}</b></a>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ThanhVienName">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Người tạo
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.ThanhVienName}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Code">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Mã lô</th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <a style="cursor: pointer;" title="{{element.Code}}"
                                                                class="link-primary"
                                                                (click)="TemPhieuKhoiTaoAdd(element.ID)"><b>{{element.Code}}</b></a>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="SoLuong">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Số lượng
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">
                                                            <b>{{element.SoLuong}}</b>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="SuDung">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Sử dụng
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="color: red; text-align: right;">
                                                            <b>{{element.SuDung}}</b>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ConLai">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Còn lại
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="color: green; text-align: right;">
                                                            <b>{{element.ConLai}}</b>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Active">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Kích hoạt
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="form-check form-check-inline">
                                                                <input class="form-check-input" type="checkbox"
                                                                    name="Active{{element.ID}}"
                                                                    [(ngModel)]="element.Active">
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="IsThuHoi">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Thu hồi
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="form-check form-check-inline">
                                                                <input class="form-check-input" type="checkbox"
                                                                    name="IsThuHoi{{element.ID}}"
                                                                    [(ngModel)]="element.IsThuHoi">
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="TemPhieuKhoiTaoService.DisplayColumns002">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: TemPhieuKhoiTaoService.DisplayColumns002;">
                                                    </tr>
                                                </table>
                                                <mat-paginator *ngIf="!NotificationService.IsMobile"
                                                    #TemPhieuKhoiTaoPaginator="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="6"
                                                    [showFirstLastButtons]></mat-paginator>
                                                <table *ngIf="NotificationService.IsMobile" mat-table matSort
                                                    #TemPhieuKhoiTaoSort="matSort"
                                                    [dataSource]="TemPhieuKhoiTaoService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Danh sách
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;">
                                                            <div class="row gx-4">
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label>Ghi nhận: &nbsp;</label>
                                                                    <label class="form-label">{{element.GhiNhan |
                                                                        date:'dd/MM/yyyy'}}</label>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label>Người tạo: &nbsp;</label>
                                                                    <label
                                                                        class="form-label">{{element.ThanhVienName}}</label>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label>Mã lô: &nbsp;</label>
                                                                    <label class="form-label">{{element.Code}}</label>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label>Số lượng: &nbsp;</label>
                                                                    <label
                                                                        class="form-label">{{element.SoLuong}}</label>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label>Sử dụng: &nbsp;</label>
                                                                    <label class="form-label"
                                                                        style="color: red;">{{element.DaSuDung}}</label>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label>Còn lại: &nbsp;</label>
                                                                    <label class="form-label"
                                                                        style="color: green;">{{element.ConLai}}</label>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <div class="form-check form-check-inline">
                                                                        <input class="form-check-input" type="checkbox"
                                                                            name="Active{{element.ID}}"
                                                                            [(ngModel)]="element.Active">
                                                                        <label class="form-label">Kích hoạt</label>
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <div class="form-check form-check-inline">
                                                                        <input class="form-check-input" type="checkbox"
                                                                            name="IsThuHoi{{element.ID}}"
                                                                            [(ngModel)]="element.IsThuHoi">
                                                                        <label class="form-label">Thu hồi</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="TemPhieuKhoiTaoService.DisplayColumnsMobile">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: TemPhieuKhoiTaoService.DisplayColumnsMobile;">
                                                    </tr>
                                                </table>
                                                <mat-paginator *ngIf="NotificationService.IsMobile"
                                                    #TemPhieuKhoiTaoPaginator="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="10000"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="TemPhieuKhoiTaoService.IsShowLoading"></app-loading>