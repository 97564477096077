import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { DownloadService } from 'src/app/shared/Download.service';

import { DanhMucQuyTrinh } from 'src/app/shared/DanhMucQuyTrinh.model';
import { DanhMucQuyTrinhService } from 'src/app/shared/DanhMucQuyTrinh.service';
@Component({
  selector: 'app-danh-muc-quy-trinh',
  templateUrl: './danh-muc-quy-trinh.component.html',
  styleUrls: ['./danh-muc-quy-trinh.component.css']
})
export class DanhMucQuyTrinhComponent implements OnInit {

  @ViewChild('DanhMucQuyTrinhSort') DanhMucQuyTrinhSort: MatSort;
  @ViewChild('DanhMucQuyTrinhPaginator') DanhMucQuyTrinhPaginator: MatPaginator;

  constructor(
    private dialog: MatDialog,
    public NotificationService: NotificationService,
    public DownloadService: DownloadService,

    public DanhMucQuyTrinhService: DanhMucQuyTrinhService,
  ) { }

  ngOnInit(): void {    
  }

  DanhMucQuyTrinhSearch() {
    this.DanhMucQuyTrinhService.SearchAll(this.DanhMucQuyTrinhSort, this.DanhMucQuyTrinhPaginator);
  }
  DanhMucQuyTrinhSave(element: DanhMucQuyTrinh) {
    this.DanhMucQuyTrinhService.FormData = element;
    this.NotificationService.warn(this.DanhMucQuyTrinhService.ComponentSaveAll(this.DanhMucQuyTrinhSort, this.DanhMucQuyTrinhPaginator));
  }
  DanhMucQuyTrinhDelete(element: DanhMucQuyTrinh) {
    this.DanhMucQuyTrinhService.BaseParameter.ID = element.ID;
    this.NotificationService.warn(this.DanhMucQuyTrinhService.ComponentDeleteAll(this.DanhMucQuyTrinhSort, this.DanhMucQuyTrinhPaginator));
  } 
}