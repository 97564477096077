import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { DownloadService } from 'src/app/shared/Download.service';

import { ToChuc } from 'src/app/shared/ToChuc.model';
import { ToChucService } from 'src/app/shared/ToChuc.service';

import { DanhMucUngDung } from 'src/app/shared/DanhMucUngDung.model';
import { DanhMucUngDungService } from 'src/app/shared/DanhMucUngDung.service';
import { ToChucDetailComponent } from '../to-chuc-detail/to-chuc-detail.component';

@Component({
  selector: 'app-to-chuc-tem-phieu',
  templateUrl: './to-chuc-tem-phieu.component.html',
  styleUrls: ['./to-chuc-tem-phieu.component.css']
})
export class ToChucTemPhieuComponent implements OnInit {

  @ViewChild('ToChucSort') ToChucSort: MatSort;
  @ViewChild('ToChucPaginator') ToChucPaginator: MatPaginator;

  constructor(
    private dialog: MatDialog,
    public NotificationService: NotificationService,
    public DownloadService: DownloadService,

    public ToChucService: ToChucService,

    public DanhMucUngDungService: DanhMucUngDungService,
  ) { }

  ngOnInit(): void {
    this.DanhMucUngDungSearch();
    this.ToChucSearch001();
  }
  DanhMucUngDungSearch() {
    this.DanhMucUngDungService.ComponentGetAllToListAsync(this.ToChucService);
  }
  ToChucSearch001() {
    this.ToChucService.BaseParameter.Active = true;    
    this.ToChucService.ComponentGetByActiveAndPageAndPageSizeAndIDToListAsync(this.ToChucService);
  }
  ToChucFilter(searchString: string) {
    this.ToChucService.FilterActive(searchString, this.ToChucService);
  }
  ToChucSearch() {
    this.ToChucService.IsShowLoading = true;
    this.ToChucService.GetByDanhMucUngDungID_SearchStringToListAsync().subscribe(
      res => {        
        this.ToChucService.ListFilter001 = (res as ToChuc[]).sort((a, b) => (a.TemPhieuSoLuong < b.TemPhieuSoLuong ? 1 : -1));
        this.ToChucService.List001 = [];
        for (let i = 0; i < this.ToChucService.ListFilter001.length; i++) {
          let ToChuc = this.ToChucService.ListFilter001[i];
          if (ToChuc.TemPhieuSoLuong > 0) {
            this.ToChucService.List001.push(ToChuc);
          }
        }
        this.ToChucService.DataSource = new MatTableDataSource(this.ToChucService.List001);
        this.ToChucService.DataSource.sort = this.ToChucSort;
        this.ToChucService.DataSource.paginator = this.ToChucPaginator;
        this.ToChucService.IsShowLoading = false;
      },
      err => {
        this.ToChucService.IsShowLoading = false;
      }
    );
  }
  ToChucAdd(ID: number) {
    this.ToChucService.IsShowLoading = true;
    this.ToChucService.BaseParameter.ID = ID;
    this.ToChucService.GetByIDAsync().subscribe(
      res => {
        this.ToChucService.FormData = res as ToChuc;
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = environment.DialogConfigWidth;
        dialogConfig.data = { ID: ID };
        const dialog = this.dialog.open(ToChucDetailComponent, dialogConfig);
        dialog.afterClosed().subscribe(() => {
          this.ToChucSearch();
        });
        this.ToChucService.IsShowLoading = false;
      },
      err => {
        this.ToChucService.IsShowLoading = false;
      }
    );
  }
}