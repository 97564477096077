import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { DownloadService } from 'src/app/shared/Download.service';

import { DanhMucPhuongThucMuaBan } from 'src/app/shared/DanhMucPhuongThucMuaBan.model';
import { DanhMucPhuongThucMuaBanService } from 'src/app/shared/DanhMucPhuongThucMuaBan.service';

@Component({
  selector: 'app-danh-muc-phuong-thuc-mua-ban',
  templateUrl: './danh-muc-phuong-thuc-mua-ban.component.html',
  styleUrls: ['./danh-muc-phuong-thuc-mua-ban.component.css']
})
export class DanhMucPhuongThucMuaBanComponent implements OnInit {

  @ViewChild('DanhMucPhuongThucMuaBanSort') DanhMucPhuongThucMuaBanSort: MatSort;
  @ViewChild('DanhMucPhuongThucMuaBanPaginator') DanhMucPhuongThucMuaBanPaginator: MatPaginator;

  constructor(
    private dialog: MatDialog,
    public NotificationService: NotificationService,
    public DownloadService: DownloadService,

    public DanhMucPhuongThucMuaBanService: DanhMucPhuongThucMuaBanService,
  ) { }

  ngOnInit(): void {    
  }

  DanhMucPhuongThucMuaBanSearch() {
    this.DanhMucPhuongThucMuaBanService.SearchAll(this.DanhMucPhuongThucMuaBanSort, this.DanhMucPhuongThucMuaBanPaginator);
  }
  DanhMucPhuongThucMuaBanSave(element: DanhMucPhuongThucMuaBan) {
    this.DanhMucPhuongThucMuaBanService.FormData = element;
    this.NotificationService.warn(this.DanhMucPhuongThucMuaBanService.ComponentSaveAll(this.DanhMucPhuongThucMuaBanSort, this.DanhMucPhuongThucMuaBanPaginator));
  }
  DanhMucPhuongThucMuaBanDelete(element: DanhMucPhuongThucMuaBan) {
    this.DanhMucPhuongThucMuaBanService.BaseParameter.ID = element.ID;
    this.NotificationService.warn(this.DanhMucPhuongThucMuaBanService.ComponentDeleteAll(this.DanhMucPhuongThucMuaBanSort, this.DanhMucPhuongThucMuaBanPaginator));
  } 
}
