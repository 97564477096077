<div class="app-body">
    <div class="row">
        <div class="col-xxl-12">
            <div class="card" style="margin-left: 1px;">
                <div class="card-body">
                    <div class="custom-tabs-container">
                        <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-link active" id="tab-DanhMucTinhThanh" data-bs-toggle="tab"
                                    href="#DanhMucTinhThanh" role="tab" aria-controls="DanhMucTinhThanh"
                                    aria-selected="true">
                                    <span class="badge bg-info" style="font-size: 16px;">Danh mục Tỉnh thành</span>
                                </a>
                            </li>
                        </ul>
                        <div class="tab-content" id="customTabContent">
                            <div class="tab-pane fade show active" id="DanhMucTinhThanh" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-4 col-sm-12 col-12">
                                        <select class="form-select" name="ParentID"
                                            [(ngModel)]="DanhMucTinhThanhService.BaseParameter.ParentID">
                                            <option *ngFor="let item of DanhMucQuocGiaService.List;" [value]="item.ID">
                                                {{item.Name}} [{{item.ID}}]</option>
                                        </select>
                                    </div>
                                    <div class="col-lg-7 col-sm-12 col-12">
                                        <input [(ngModel)]="DanhMucTinhThanhService.BaseParameter.SearchString"
                                            placeholder="Tìm ..." class="form-control"
                                            (keyup.enter)="DanhMucTinhThanhSearch()">
                                    </div>
                                    <div class="col-lg-1 col-sm-12 col-12">
                                        <a style="width: 100%;" class="btn btn-success" title="Tìm"
                                            (click)="DanhMucTinhThanhSearch()"><i
                                                class="bi bi-search"></i></a>&nbsp;&nbsp;&nbsp;
                                    </div>
                                </div>
                                <div class="card mb-4 card-info">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">Danh sách
                                            <span
                                                *ngIf="DanhMucTinhThanhService.List">({{DanhMucTinhThanhService.List.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table *ngIf="!NotificationService.IsMobile" mat-table matSort
                                                    #DanhMucTinhThanhSort="matSort"
                                                    [dataSource]="DanhMucTinhThanhService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            STT
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;"
                                                            style="text-align: center;">
                                                            <div *ngIf="DanhMucTinhThanhPaginator">
                                                                {{DanhMucTinhThanhPaginator.pageSize *
                                                                DanhMucTinhThanhPaginator.pageIndex + i + 1}}
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ID">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>ID
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.ID}}
                                                        </td>
                                                    </ng-container>                                                   
                                                    <ng-container matColumnDef="Name">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tiêu đề
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="text"
                                                                placeholder="Tiêu đề" name="Name{{element.ID}}"
                                                                [(ngModel)]="element.Name">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="TypeName">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>TypeName
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="text"
                                                                placeholder="TypeName" name="TypeName{{element.ID}}"
                                                                [(ngModel)]="element.TypeName">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Display">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Display
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="text"
                                                                placeholder="Display" name="Display{{element.ID}}"
                                                                [(ngModel)]="element.Display">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="FileName">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>FileName
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="text"
                                                                placeholder="FileName" name="FileName{{element.ID}}"
                                                                [(ngModel)]="element.FileName">
                                                        </td>
                                                    </ng-container>  
                                                    <ng-container matColumnDef="ParentID">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Parent
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <select class="form-select" name="ParentID{{element.ID}}"
                                                                [(ngModel)]="element.ParentID">
                                                                <option *ngFor="let item of DanhMucQuocGiaService.List;"
                                                                    [value]="item.ID">
                                                                    {{item.Name}} [{{item.ID}}]</option>
                                                            </select>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="KinhDo">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Kinh độ (Longitude)</th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="text" placeholder="Kinh độ (Longitude)"
                                                                name="KinhDo{{element.ID}}" [(ngModel)]="element.KinhDo">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ViDo">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Vĩ độ (Latitude)
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="text" placeholder="Vĩ độ (Latitude)"
                                                                name="ViDo{{element.ID}}" [(ngModel)]="element.ViDo">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="SortOrder">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Sắp xếp
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="number" placeholder="0"
                                                                name="SortOrder{{element.ID}}"
                                                                [(ngModel)]="element.SortOrder"
                                                                style="text-align: right;">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Active">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Kích hoạt
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="form-check form-check-inline">
                                                                <input class="form-check-input" type="checkbox"
                                                                    name="Active{{element.ID}}"
                                                                    [(ngModel)]="element.Active">
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Save">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <a class="btn btn-info"
                                                                (click)="DanhMucTinhThanhSave(element)"><i
                                                                    class="bi bi-sd-card"></i>
                                                            </a>&nbsp;&nbsp;&nbsp;
                                                            <a class="btn btn-success"
                                                                (click)="DanhMucTinhThanhToaDoAdd(element)"><i
                                                                    class="bi bi-arrows-fullscreen"></i>
                                                            </a>&nbsp;&nbsp;&nbsp;
                                                            <a class="btn btn-danger"
                                                                (click)="DanhMucTinhThanhDelete(element)"
                                                                *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                                            </a>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="DanhMucTinhThanhService.DisplayColumns003">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: DanhMucTinhThanhService.DisplayColumns003;">
                                                    </tr>
                                                </table>
                                                <mat-paginator *ngIf="!NotificationService.IsMobile"
                                                    #DanhMucTinhThanhPaginator="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="6"
                                                    [showFirstLastButtons]></mat-paginator>
                                                <table *ngIf="NotificationService.IsMobile" mat-table matSort
                                                    #DanhMucTinhThanhSort="matSort"
                                                    [dataSource]="DanhMucTinhThanhService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Danh sách
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;">
                                                            <div class="row gx-4">
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Code</label>
                                                                    <input class="form-control" type="text"
                                                                        placeholder="Code" name="Code{{element.ID}}"
                                                                        [(ngModel)]="element.Code">
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Tiêu đề</label>
                                                                    <input class="form-control" type="text"
                                                                        placeholder="Tiêu đề" name="Name{{element.ID}}"
                                                                        [(ngModel)]="element.Name">
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Hiển thị</label>
                                                                    <input class="form-control" type="text"
                                                                        placeholder="Hiển thị" name="Display{{element.ID}}"
                                                                        [(ngModel)]="element.Display">
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Kinh độ (Longitude)</label>
                                                                    <input class="form-control" type="text"
                                                                        placeholder="Kinh độ (Longitude)" name="KinhDo{{element.ID}}"
                                                                        [(ngModel)]="element.KinhDo">
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Vĩ độ (Latitude)</label>
                                                                    <input class="form-control" type="text"
                                                                        placeholder="Vĩ độ (Latitude)" name="ViDo{{element.ID}}"
                                                                        [(ngModel)]="element.ViDo">
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Ghi chú</label>
                                                                    <input class="form-control" type="text"
                                                                        placeholder="Ghi chú" name="Note{{element.ID}}"
                                                                        [(ngModel)]="element.Note">
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Parent</label>
                                                                    <input class="form-control" type="number"
                                                                        placeholder="0" name="ParentID{{element.ID}}"
                                                                        [(ngModel)]="element.ParentID"
                                                                        style="text-align: right;">
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Sắp xếp</label>
                                                                    <input class="form-control" type="number"
                                                                        placeholder="0" name="SortOrder{{element.ID}}"
                                                                        [(ngModel)]="element.SortOrder"
                                                                        style="text-align: right;">
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <div class="form-check form-check-inline">
                                                                        <input class="form-check-input" type="checkbox"
                                                                            name="Active{{element.ID}}"
                                                                            [(ngModel)]="element.Active">
                                                                        <label class="form-label">Kích hoạt</label>
                                                                    </div>
                                                                </div>
                                                                <div class="row gx-4">
                                                                    <div class="col-lg-6 col-sm-6 col-6">
                                                                        <a class="btn btn-info" style="width: 100%;"
                                                                            (click)="DanhMucTinhThanhSave(element)"><i
                                                                                class="bi bi-sd-card"></i>
                                                                        </a>
                                                                    </div>
                                                                    <div class="col-lg-6 col-sm-6 col-6">
                                                                        <a class="btn btn-danger" style="width: 100%;"
                                                                            (click)="DanhMucTinhThanhDelete(element)"
                                                                            *ngIf="element.ID>0"><i
                                                                                class="bi bi-trash"></i>
                                                                        </a>
                                                                    </div>
                                                                    <br />
                                                                    <br />
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="DanhMucTinhThanhService.DisplayColumnsMobile">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: DanhMucTinhThanhService.DisplayColumnsMobile;">
                                                    </tr>
                                                </table>
                                                <mat-paginator *ngIf="NotificationService.IsMobile"
                                                    #DanhMucTinhThanhPaginator="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="10000"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="DanhMucTinhThanhService.IsShowLoading"></app-loading>