import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { DownloadService } from 'src/app/shared/Download.service';

import { LoHang } from 'src/app/shared/LoHang.model';
import { LoHangService } from 'src/app/shared/LoHang.service';

import { ThanhVien } from 'src/app/shared/ThanhVien.model';
import { ThanhVienService } from 'src/app/shared/ThanhVien.service';

import { LoHangDetailComponent } from '../lo-hang-detail/lo-hang-detail.component';
import { DonViLoHangDetailComponent } from '../don-vi-lo-hang-detail/don-vi-lo-hang-detail.component';


@Component({
  selector: 'app-don-vi-lo-hang',
  templateUrl: './don-vi-lo-hang.component.html',
  styleUrls: ['./don-vi-lo-hang.component.css']
})
export class DonViLoHangComponent implements OnInit {

  @ViewChild('LoHangSort') LoHangSort: MatSort;
  @ViewChild('LoHangPaginator') LoHangPaginator: MatPaginator;

  constructor(
    private dialog: MatDialog,
    public NotificationService: NotificationService,
    public DownloadService: DownloadService,

    public ThanhVienService: ThanhVienService,

    public LoHangService: LoHangService,

  ) { }

  ngOnInit(): void {
    this.ThanhVienService.GetLogin();
    this.LoHangSearch();
  }
  DateBatDau(value) {
    this.LoHangService.BaseParameter.BatDau = new Date(value);
  }
  DateKetThuc(value) {
    this.LoHangService.BaseParameter.KetThuc = new Date(value);
  }
  LoHangSearch() {
    this.LoHangService.IsShowLoading = true;
    this.LoHangService.BaseParameter.ParentID = this.ThanhVienService.FormDataLogin.ToChucID;
    this.LoHangService.GetBySearchString_BatDau_KetThuc_ParentIDToListAsync().subscribe(
      res => {
        this.LoHangService.List = (res as LoHang[]).sort((a, b) => (a.Name > b.Name ? 1 : -1));
        this.LoHangService.DataSource = new MatTableDataSource(this.LoHangService.List);
        this.LoHangService.DataSource.sort = this.LoHangSort;
        this.LoHangService.DataSource.paginator = this.LoHangPaginator;
        this.LoHangService.IsShowLoading = false;
      },
      err => {
        this.LoHangService.IsShowLoading = false;
      }
    );
  }
  LoHangCopy(element: LoHang) {
    this.LoHangService.IsShowLoading = true;
    this.LoHangService.FormData = element;
    this.LoHangService.CopyAsync().subscribe(
      res => {
        this.LoHangSearch();
        this.LoHangService.IsShowLoading = false;
      },
      err => {
        this.LoHangService.IsShowLoading = false;
      }
    );
  }
  LoHangDelete(element: LoHang) {
    if (confirm(environment.DeleteConfirm)) {
      this.LoHangService.IsShowLoading = true;
      this.LoHangService.BaseParameter.ID = element.ID;
      this.LoHangService.RemoveAsync().subscribe(
        res => {
          this.LoHangSearch();
          this.LoHangService.IsShowLoading = false;
        },
        err => {
          this.LoHangService.IsShowLoading = false;
        }
      );
    }
  }
  LoHangAdd(ID: number) {
    if (this.ThanhVienService.FormDataLogin) {
      if (this.ThanhVienService.FormDataLogin.ToChucID > 0) {
        this.LoHangService.IsShowLoading = true;
        this.LoHangService.BaseParameter.ID = ID;
        this.LoHangService.GetByIDAsync().subscribe(
          res => {
            this.LoHangService.FormData = res as LoHang;
            this.LoHangService.FormData.ParentID = this.ThanhVienService.FormDataLogin.ToChucID;
            const dialogConfig = new MatDialogConfig();
            dialogConfig.disableClose = true;
            dialogConfig.autoFocus = true;
            dialogConfig.width = environment.DialogConfigWidth;
            dialogConfig.data = { ID: ID };
            const dialog = this.dialog.open(DonViLoHangDetailComponent, dialogConfig);
            dialog.afterClosed().subscribe(() => {
              this.LoHangSearch();
            });
            this.LoHangService.IsShowLoading = false;
          },
          err => {
            this.LoHangService.IsShowLoading = false;
          }
        );
      }
    }
  }
}