import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { DownloadService } from 'src/app/shared/Download.service';

import { DanhMucPhuongThucSanXuat } from 'src/app/shared/DanhMucPhuongThucSanXuat.model';
import { DanhMucPhuongThucSanXuatService } from 'src/app/shared/DanhMucPhuongThucSanXuat.service';

@Component({
  selector: 'app-danh-muc-phuong-thuc-san-xuat',
  templateUrl: './danh-muc-phuong-thuc-san-xuat.component.html',
  styleUrls: ['./danh-muc-phuong-thuc-san-xuat.component.css']
})
export class DanhMucPhuongThucSanXuatComponent implements OnInit {

  @ViewChild('DanhMucPhuongThucSanXuatSort') DanhMucPhuongThucSanXuatSort: MatSort;
  @ViewChild('DanhMucPhuongThucSanXuatPaginator') DanhMucPhuongThucSanXuatPaginator: MatPaginator;

  constructor(
    private dialog: MatDialog,
    public NotificationService: NotificationService,
    public DownloadService: DownloadService,

    public DanhMucPhuongThucSanXuatService: DanhMucPhuongThucSanXuatService,
  ) { }

  ngOnInit(): void {    
  }

  DanhMucPhuongThucSanXuatSearch() {
    this.DanhMucPhuongThucSanXuatService.SearchAll(this.DanhMucPhuongThucSanXuatSort, this.DanhMucPhuongThucSanXuatPaginator);
  }
  DanhMucPhuongThucSanXuatSave(element: DanhMucPhuongThucSanXuat) {
    this.DanhMucPhuongThucSanXuatService.FormData = element;
    this.NotificationService.warn(this.DanhMucPhuongThucSanXuatService.ComponentSaveAll(this.DanhMucPhuongThucSanXuatSort, this.DanhMucPhuongThucSanXuatPaginator));
  }
  DanhMucPhuongThucSanXuatDelete(element: DanhMucPhuongThucSanXuat) {
    this.DanhMucPhuongThucSanXuatService.BaseParameter.ID = element.ID;
    this.NotificationService.warn(this.DanhMucPhuongThucSanXuatService.ComponentDeleteAll(this.DanhMucPhuongThucSanXuatSort, this.DanhMucPhuongThucSanXuatPaginator));
  } 
}
