import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { DownloadService } from 'src/app/shared/Download.service';

import { DanhMucNhomSanPham } from 'src/app/shared/DanhMucNhomSanPham.model';
import { DanhMucNhomSanPhamService } from 'src/app/shared/DanhMucNhomSanPham.service';

@Component({
  selector: 'app-danh-muc-nhom-san-pham',
  templateUrl: './danh-muc-nhom-san-pham.component.html',
  styleUrls: ['./danh-muc-nhom-san-pham.component.css']
})
export class DanhMucNhomSanPhamComponent implements OnInit {

  @ViewChild('DanhMucNhomSanPhamSort') DanhMucNhomSanPhamSort: MatSort;
  @ViewChild('DanhMucNhomSanPhamPaginator') DanhMucNhomSanPhamPaginator: MatPaginator;

  constructor(
    private dialog: MatDialog,
    public NotificationService: NotificationService,
    public DownloadService: DownloadService,

    public DanhMucNhomSanPhamService: DanhMucNhomSanPhamService,
  ) { }

  ngOnInit(): void {    
  }

  DanhMucNhomSanPhamSearch() {
    this.DanhMucNhomSanPhamService.SearchAll(this.DanhMucNhomSanPhamSort, this.DanhMucNhomSanPhamPaginator);
  }
  DanhMucNhomSanPhamSave(element: DanhMucNhomSanPham) {
    this.DanhMucNhomSanPhamService.FormData = element;
    this.NotificationService.warn(this.DanhMucNhomSanPhamService.ComponentSaveAll(this.DanhMucNhomSanPhamSort, this.DanhMucNhomSanPhamPaginator));
  }
  DanhMucNhomSanPhamDelete(element: DanhMucNhomSanPham) {
    this.DanhMucNhomSanPhamService.BaseParameter.ID = element.ID;
    this.NotificationService.warn(this.DanhMucNhomSanPhamService.ComponentDeleteAll(this.DanhMucNhomSanPhamSort, this.DanhMucNhomSanPhamPaginator));
  } 
}
