import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { DownloadService } from 'src/app/shared/Download.service';

import { DanhMucChauLuc } from 'src/app/shared/DanhMucChauLuc.model';
import { DanhMucChauLucService } from 'src/app/shared/DanhMucChauLuc.service';

@Component({
  selector: 'app-danh-muc-chau-luc',
  templateUrl: './danh-muc-chau-luc.component.html',
  styleUrls: ['./danh-muc-chau-luc.component.css']
})
export class DanhMucChauLucComponent implements OnInit {

  @ViewChild('DanhMucChauLucSort') DanhMucChauLucSort: MatSort;
  @ViewChild('DanhMucChauLucPaginator') DanhMucChauLucPaginator: MatPaginator;

  constructor(
    private dialog: MatDialog,
    public NotificationService: NotificationService,
    public DownloadService: DownloadService,

    public DanhMucChauLucService: DanhMucChauLucService,
  ) { }

  ngOnInit(): void {    
  }

  DanhMucChauLucSearch() {
    this.DanhMucChauLucService.SearchAll(this.DanhMucChauLucSort, this.DanhMucChauLucPaginator);
  }
  DanhMucChauLucSave(element: DanhMucChauLuc) {
    this.DanhMucChauLucService.FormData = element;
    this.NotificationService.warn(this.DanhMucChauLucService.ComponentSaveAll(this.DanhMucChauLucSort, this.DanhMucChauLucPaginator));
  }
  DanhMucChauLucDelete(element: DanhMucChauLuc) {
    this.DanhMucChauLucService.BaseParameter.ID = element.ID;
    this.NotificationService.warn(this.DanhMucChauLucService.ComponentDeleteAll(this.DanhMucChauLucSort, this.DanhMucChauLucPaginator));
  } 
}