import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-danh-muc-tinh-trang',
  templateUrl: './danh-muc-tinh-trang.component.html',
  styleUrls: ['./danh-muc-tinh-trang.component.css']
})
export class DanhMucTinhTrangComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
