<div class="app-body">
    <div class="row">
        <div class="col-xxl-12">
            <div class="card" style="margin-left: 1px;">
                <div class="card-body">
                    <div class="custom-tabs-container">
                        <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-link active" id="tab-Upload" data-bs-toggle="tab" href="#Upload"
                                    role="tab" aria-controls="Upload" aria-selected="true">
                                    <span class="badge bg-info" style="font-size: 16px;">Upload</span>
                                </a>
                            </li>
                        </ul>
                        <div class="tab-content" id="customTabContent">
                            <div class="tab-pane fade show active" id="Upload" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-4 col-sm-12 col-12">
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <h1>Tỉnh thành</h1>
                                        </div>                                        
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Bước 01:</label> Chọn file JSON
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <input class="form-control" type="file" #UploadDanhMucTinhThanh
                                                (change)="DanhMucTinhThanhChange($event.target.files)">
                                        </div>
                                        <br />
                                        <button [disabled]="!IsDanhMucTinhThanh" class="btn btn-info"
                                            (click)="DanhMucTinhThanhSave()"
                                            style="width: 100%;">Upload</button>
                                    </div>
                                    <div class="col-lg-4 col-sm-12 col-12">
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <h1>Quận huyện</h1>
                                        </div>                                        
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Bước 01:</label> Chọn file JSON
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <input class="form-control" type="file" #UploadDanhMucQuanHuyen
                                                (change)="DanhMucQuanHuyenChange($event.target.files)">
                                        </div>
                                        <br />
                                        <button [disabled]="!IsDanhMucQuanHuyen" class="btn btn-info"
                                            (click)="DanhMucQuanHuyenSave()"
                                            style="width: 100%;">Upload</button>
                                    </div>
                                    <div class="col-lg-4 col-sm-12 col-12">
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <h1>Xã phường</h1>
                                        </div>                                        
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Bước 01:</label> Chọn file JSON
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <input class="form-control" type="file" #UploadDanhMucXaPhuong
                                                (change)="DanhMucXaPhuongChange($event.target.files)">
                                        </div>
                                        <br />
                                        <button [disabled]="!IsDanhMucXaPhuong" class="btn btn-info"
                                            (click)="DanhMucXaPhuongSave()"
                                            style="width: 100%;">Upload</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="DanhMucTinhThanhService.IsShowLoading"></app-loading>