import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { DownloadService } from 'src/app/shared/Download.service';

import { DanhMucUngDung } from 'src/app/shared/DanhMucUngDung.model';
import { DanhMucUngDungService } from 'src/app/shared/DanhMucUngDung.service';

import { LoHang } from 'src/app/shared/LoHang.model';
import { LoHangService } from 'src/app/shared/LoHang.service';

import { ToChuc } from 'src/app/shared/ToChuc.model';
import { ToChucService } from 'src/app/shared/ToChuc.service';

import { LoHangDetailComponent } from '../lo-hang-detail/lo-hang-detail.component';
import { ToChucDetailComponent } from '../to-chuc-detail/to-chuc-detail.component';

@Component({
  selector: 'app-lo-hang',
  templateUrl: './lo-hang.component.html',
  styleUrls: ['./lo-hang.component.css']
})
export class LoHangComponent implements OnInit {

  @ViewChild('LoHangSort') LoHangSort: MatSort;
  @ViewChild('LoHangPaginator') LoHangPaginator: MatPaginator;

  constructor(
    private dialog: MatDialog,
    public NotificationService: NotificationService,
    public DownloadService: DownloadService,

    public LoHangService: LoHangService,
    public ToChucService: ToChucService,

    public DanhMucUngDungService: DanhMucUngDungService,
  ) { }

  ngOnInit(): void {
    this.DanhMucUngDungSearch();
  }
  DanhMucUngDungSearch() {
    this.DanhMucUngDungService.ComponentGetAllToListAsync(this.LoHangService);
  }
  DateBatDau(value) {
    this.LoHangService.BaseParameter.BatDau = new Date(value);
  }
  DateKetThuc(value) {
    this.LoHangService.BaseParameter.KetThuc = new Date(value);
  }
  LoHangSearch() {
    this.LoHangService.IsShowLoading = true;
    this.LoHangService.BaseParameter.Active = true;
    this.LoHangService.GetByDanhMucUngDungIDAndActiveAndBatDauAndKetThuc_SearchStringToListAsync().subscribe(
      res => {
        this.LoHangService.List = (res as LoHang[]).sort((a, b) => (a.DangKy < b.DangKy ? 1 : -1));
        this.LoHangService.DataSource = new MatTableDataSource(this.LoHangService.List);
        this.LoHangService.DataSource.sort = this.LoHangSort;
        this.LoHangService.DataSource.paginator = this.LoHangPaginator;
        this.LoHangService.IsShowLoading = false;
      },
      err => {
        this.LoHangService.IsShowLoading = false;
      }
    );
  }
  LoHangCopy(element: LoHang) {
    this.LoHangService.IsShowLoading = true;
    this.LoHangService.FormData = element;
    this.LoHangService.CopyAsync().subscribe(
      res => {
        this.LoHangSearch();
        this.LoHangService.IsShowLoading = false;
      },
      err => {
        this.LoHangService.IsShowLoading = false;
      }
    );
  }
  LoHangDelete(element: LoHang) {
    if (confirm(environment.DeleteConfirm)) {
      this.LoHangService.IsShowLoading = true;
      this.LoHangService.BaseParameter.ID = element.ID;
      this.LoHangService.RemoveAsync().subscribe(
        res => {
          this.LoHangSearch();
          this.LoHangService.IsShowLoading = false;
        },
        err => {
          this.LoHangService.IsShowLoading = false;
        }
      );
    }
  }
  LoHangAdd(ID: number) {
    this.LoHangService.IsShowLoading = true;
    this.LoHangService.BaseParameter.ID = ID;
    this.LoHangService.GetByIDAsync().subscribe(
      res => {
        this.LoHangService.FormData = res as LoHang;        
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = environment.DialogConfigWidth;
        dialogConfig.data = { ID: ID };
        const dialog = this.dialog.open(LoHangDetailComponent, dialogConfig);
        dialog.afterClosed().subscribe(() => {
          this.LoHangSearch();
        });
        this.LoHangService.IsShowLoading = false;
      },
      err => {
        this.LoHangService.IsShowLoading = false;
      }
    );
  }
  ToChucAdd(ID: number) {
    this.LoHangService.IsShowLoading = true;
    this.ToChucService.BaseParameter.ID = ID;
    this.ToChucService.GetByIDAsync().subscribe(
      res => {
        this.ToChucService.FormData = res as ToChuc;        
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = environment.DialogConfigWidth;
        dialogConfig.data = { ID: ID };
        const dialog = this.dialog.open(ToChucDetailComponent, dialogConfig);
        dialog.afterClosed().subscribe(() => {          
        });
        this.LoHangService.IsShowLoading = false;
      },
      err => {
        this.LoHangService.IsShowLoading = false;
      }
    );
  }
}