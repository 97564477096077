<div class="app-body">
    <div class="row">
        <div class="col-xxl-12">
            <div class="card" style="margin-left: 1px;">
                <div class="card-body">
                    <div class="custom-tabs-container">
                        <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-link active" id="tab-LoHang" data-bs-toggle="tab" href="#LoHang"
                                    role="tab" aria-controls="LoHang" aria-selected="true">
                                    <span class="badge bg-info" style="font-size: 16px;">Lô Tem (Mã số truy vết sản
                                        phẩm) do Đơn vị tự sản xuất</span>
                                </a>
                            </li>
                        </ul>
                        <div class="tab-content" id="customTabContent">
                            <div class="tab-pane fade show active" id="LoHang" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-3 col-sm-12 col-12">
                                        <label *ngIf="!NotificationService.IsMobile" class="form-label">Ứng dụng</label>
                                        <select class="form-select" name="DanhMucUngDungID"
                                            [(ngModel)]="LoHangService.BaseParameter.DanhMucUngDungID">
                                            <option *ngFor="let item of DanhMucUngDungService.List;" [value]="item.ID">
                                                {{item.Name}} [{{item.ID}}]</option>
                                        </select>
                                    </div>
                                    <div class="col-lg-4 col-sm-12 col-12">
                                        <label *ngIf="!NotificationService.IsMobile" class="form-label">Đơn vị sử
                                            dụng</label>
                                        <mat-select class="form-control" name="LoHangService.BaseParameter.SearchString"
                                            [(ngModel)]="LoHangService.BaseParameter.SearchString">
                                            <input placeholder="Tìm..." class="form-control"
                                                (keyup)="ToChucFilter($event.target.value)">
                                            <mat-option [value]="">
                                                Tất cả
                                            </mat-option>
                                            <mat-option *ngFor="let item of ToChucService.ListFilter"
                                                [value]="item.Name">
                                                {{item.DanhMucUngDungName}} - <b>{{item.Name}}</b> - {{item.DienThoai}}
                                            </mat-option>
                                        </mat-select>
                                    </div>
                                    <div class="col-lg-3 col-sm-12 col-12">
                                        <label *ngIf="!NotificationService.IsMobile" class="form-label">.</label>
                                        <input [(ngModel)]="LoHangService.BaseParameter.SearchString"
                                            placeholder="Tìm ..." class="form-control" (keyup.enter)="LoHangSearch()">
                                    </div>
                                    <div class="col-lg-1 col-sm-12 col-12">
                                        <label *ngIf="!NotificationService.IsMobile" class="form-label">.</label>
                                        <a style="width: 100%;" class="btn btn-success" title="Tìm"
                                            (click)="LoHangSearch()"><i class="bi bi-search"></i></a>&nbsp;&nbsp;&nbsp;
                                    </div>
                                    <div class="col-lg-1 col-sm-12 col-12">
                                        <label *ngIf="!NotificationService.IsMobile" class="form-label">.</label>
                                        <a style="width: 100%;" class="btn btn-success" title="Thêm mới"
                                            (click)="LoHangAdd(0)"><i class="bi bi-plus"></i></a>
                                    </div>
                                </div>
                                <div class="card mb-4 card-info">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">Danh sách
                                            <span *ngIf="LoHangService.List">({{LoHangService.List.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table *ngIf="!NotificationService.IsMobile" mat-table matSort
                                                    #LoHangSort="matSort" [dataSource]="LoHangService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            STT
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;"
                                                            style="text-align: center;">
                                                            <div *ngIf="LoHangPaginator">
                                                                {{LoHangPaginator.pageSize *
                                                                LoHangPaginator.pageIndex + i + 1}}
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ID">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>ID
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <a style="cursor: pointer;" title="{{element.Code}}"
                                                                class="link-primary"
                                                                (click)="LoHangAdd(element.ID)"><b>{{element.ID}}</b></a>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DangKy">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Đăng ký
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <a style="cursor: pointer;" title="{{element.MaLoHang}}"
                                                                class="link-primary"
                                                                (click)="LoHangAdd(element.ID)"><b>{{element.DangKy
                                                                    |
                                                                    date:'dd/MM/yyyy'}}</b></a>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ParentName">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Đơn vị
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <a style="cursor: pointer;" title="{{element.ParentName}}"
                                                                class="link-primary"
                                                                (click)="ToChucAdd(element.ParentID)"><b>{{element.ParentName}}</b></a>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="MaLoHang">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Mã lô đóng
                                                            gói hàng hóa (Dùng cho tra cứu)</th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <a style="cursor: pointer;" title="{{element.MaLoHang}}"
                                                                class="link-primary"
                                                                (click)="LoHangAdd(element.ID)"><b>{{element.MaLoHang}}</b></a>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="SanPhamSoLuongMa">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Số lượng
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">
                                                            <b>{{element.SanPhamSoLuongMa}}</b>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Active">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Đăng ký
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="form-check form-check-inline">
                                                                <input class="form-check-input" type="checkbox"
                                                                    name="Active{{element.ID}}"
                                                                    [(ngModel)]="element.Active">
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="IsPheDuyet">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Phê duyệt
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="form-check form-check-inline">
                                                                <input class="form-check-input" type="checkbox"
                                                                    name="IsPheDuyet{{element.ID}}"
                                                                    [(ngModel)]="element.IsPheDuyet">
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="IsThuHoi">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Thu hồi
                                                            (nếu có)
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="form-check form-check-inline">
                                                                <input class="form-check-input" type="checkbox"
                                                                    name="IsThuHoi{{element.ID}}"
                                                                    [(ngModel)]="element.IsThuHoi">
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="LoHangService.DisplayColumns003">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: LoHangService.DisplayColumns003;">
                                                    </tr>
                                                </table>
                                                <mat-paginator *ngIf="!NotificationService.IsMobile"
                                                    #LoHangPaginator="matPaginator" [pageSizeOptions]="[10,20,50,100]"
                                                    [pageSize]="6" [showFirstLastButtons]></mat-paginator>
                                                <table *ngIf="NotificationService.IsMobile" mat-table matSort
                                                    #LoHangSort="matSort" [dataSource]="LoHangService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Danh sách
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;">
                                                            <div class="row gx-4">
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="LoHangService.DisplayColumnsMobile">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: LoHangService.DisplayColumnsMobile;">
                                                    </tr>
                                                </table>
                                                <mat-paginator *ngIf="NotificationService.IsMobile"
                                                    #LoHangPaginator="matPaginator" [pageSizeOptions]="[10,20,50,100]"
                                                    [pageSize]="10000" [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="LoHangService.IsShowLoading"></app-loading>