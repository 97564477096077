import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CKEditorModule } from 'ngx-ckeditor';
import { ChartsModule } from 'ng2-charts';
import { CookieService } from 'ngx-cookie-service';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MaterialModule } from './material/material.module';
import { GoogleMapsModule } from '@angular/google-maps';
import { NotificationService } from './shared/Notification.service';
import { AppComponent } from './app.component';
import { LoadingComponent } from './loading/loading.component';
import { HomepageComponent } from './homepage/homepage.component';
import { LoginComponent } from './login/login.component';
import { DanhMucNgonNguComponent } from './danh-muc-ngon-ngu/danh-muc-ngon-ngu.component';
import { DanhMucQuocGiaComponent } from './danh-muc-quoc-gia/danh-muc-quoc-gia.component';
import { DanhMucThanhVienComponent } from './danh-muc-thanh-vien/danh-muc-thanh-vien.component';
import { DanhMucTinhThanhComponent } from './danh-muc-tinh-thanh/danh-muc-tinh-thanh.component';
import { DanhMucTinhTrangComponent } from './danh-muc-tinh-trang/danh-muc-tinh-trang.component';
import { DanhMucUngDungComponent } from './danh-muc-ung-dung/danh-muc-ung-dung.component';
import { DanhMucXaPhuongComponent } from './danh-muc-xa-phuong/danh-muc-xa-phuong.component';
import { ThanhVienComponent } from './thanh-vien/thanh-vien.component';
import { ThanhVienChucNangComponent } from './thanh-vien-chuc-nang/thanh-vien-chuc-nang.component';
import { ThanhVienKhuVucComponent } from './thanh-vien-khu-vuc/thanh-vien-khu-vuc.component';
import { ThanhVienLichSuTruyCapComponent } from './thanh-vien-lich-su-truy-cap/thanh-vien-lich-su-truy-cap.component';
import { ThanhVienTokenComponent } from './thanh-vien-token/thanh-vien-token.component';
import { DanhMucChucNangComponent } from './danh-muc-chuc-nang/danh-muc-chuc-nang.component';
import { DanhMucQuanHuyenComponent } from './danh-muc-quan-huyen/danh-muc-quan-huyen.component';
import { DanhMucChauLucComponent } from './danh-muc-chau-luc/danh-muc-chau-luc.component';
import { DanhMucToChucComponent } from './danh-muc-to-chuc/danh-muc-to-chuc.component';
import { ToChucComponent } from './to-chuc/to-chuc.component';
import { ToChucDetailComponent } from './to-chuc-detail/to-chuc-detail.component';
import { ThanhVienDetailComponent } from './thanh-vien-detail/thanh-vien-detail.component';
import { ThanhVienThongTinComponent } from './thanh-vien-thong-tin/thanh-vien-thong-tin.component';
import { DanhMucDonViTinhComponent } from './danh-muc-don-vi-tinh/danh-muc-don-vi-tinh.component';
import { DanhMucLoaiHinhComponent } from './danh-muc-loai-hinh/danh-muc-loai-hinh.component';
import { DanhMucNganhNgheComponent } from './danh-muc-nganh-nghe/danh-muc-nganh-nghe.component';
import { DanhMucNhomSanPhamComponent } from './danh-muc-nhom-san-pham/danh-muc-nhom-san-pham.component';
import { DanhMucPhuongThucSanXuatComponent } from './danh-muc-phuong-thuc-san-xuat/danh-muc-phuong-thuc-san-xuat.component';
import { DanhMucQuyTrinhComponent } from './danh-muc-quy-trinh/danh-muc-quy-trinh.component';
import { DanhMucSanPhamComponent } from './danh-muc-san-pham/danh-muc-san-pham.component';
import { DanhMucTapTinDinhKemPhanLoaiComponent } from './danh-muc-tap-tin-dinh-kem-phan-loai/danh-muc-tap-tin-dinh-kem-phan-loai.component';
import { LoHangComponent } from './lo-hang/lo-hang.component';
import { LoHangDetailComponent } from './lo-hang-detail/lo-hang-detail.component';
import { HuongDanComponent } from './huong-dan/huong-dan.component';
import { GioiThieuComponent } from './gioi-thieu/gioi-thieu.component';
import { LoHangMapComponent } from './lo-hang-map/lo-hang-map.component';
import { TemPhieuLichSuTruyCapDetailComponent } from './tem-phieu-lich-su-truy-cap-detail/tem-phieu-lich-su-truy-cap-detail.component';
import { TemPhieuKhoiTaoComponent } from './tem-phieu-khoi-tao/tem-phieu-khoi-tao.component';
import { TemPhieuKhoiTaoDetailComponent } from './tem-phieu-khoi-tao-detail/tem-phieu-khoi-tao-detail.component';
import { ToChucTemPhieuComponent } from './to-chuc-tem-phieu/to-chuc-tem-phieu.component';
import { TemPhieuKhoiTaoDetailToChucComponent } from './tem-phieu-khoi-tao-detail-to-chuc/tem-phieu-khoi-tao-detail-to-chuc.component';
import { DonViToChucDetailComponent } from './don-vi-to-chuc-detail/don-vi-to-chuc-detail.component';
import { DonViLoHangComponent } from './don-vi-lo-hang/don-vi-lo-hang.component';
import { DonViToChucKhoTemComponent } from './don-vi-to-chuc-kho-tem/don-vi-to-chuc-kho-tem.component';
import { DonViToChucTemPhieuKhoiTaoComponent } from './don-vi-to-chuc-tem-phieu-khoi-tao/don-vi-to-chuc-tem-phieu-khoi-tao.component';
import { DonViToChucTemPhieuKhoiTaoChiTietComponent } from './don-vi-to-chuc-tem-phieu-khoi-tao-chi-tiet/don-vi-to-chuc-tem-phieu-khoi-tao-chi-tiet.component';
import { DonViToChucTemPhieuKhoiTaoChiTietActiveComponent } from './don-vi-to-chuc-tem-phieu-khoi-tao-chi-tiet-active/don-vi-to-chuc-tem-phieu-khoi-tao-chi-tiet-active.component';
import { DonViLoHangDetailComponent } from './don-vi-lo-hang-detail/don-vi-lo-hang-detail.component';
import { TemPhieuKhoiTao001Component } from './tem-phieu-khoi-tao001/tem-phieu-khoi-tao001.component';
import { ThanhVienThongBaoComponent } from './thanh-vien-thong-bao/thanh-vien-thong-bao.component';
import { LoHangInfoComponent } from './lo-hang-info/lo-hang-info.component';
import { DonViLoHangInfoComponent } from './don-vi-lo-hang-info/don-vi-lo-hang-info.component';
import { ThanhVienChucNangDetailComponent } from './thanh-vien-chuc-nang-detail/thanh-vien-chuc-nang-detail.component';
import { DanhMucPhuongThucMuaBanComponent } from './danh-muc-phuong-thuc-mua-ban/danh-muc-phuong-thuc-mua-ban.component';
import { DanhMucTinhThanhToaDoComponent } from './danh-muc-tinh-thanh-toa-do/danh-muc-tinh-thanh-toa-do.component';
import { ThoiTietComponent } from './thoi-tiet/thoi-tiet.component';
import { DanhMucTinhThanhToaDoDetailComponent } from './danh-muc-tinh-thanh-toa-do-detail/danh-muc-tinh-thanh-toa-do-detail.component';
import { UploadComponent } from './upload/upload.component';




@NgModule({
  declarations: [
    AppComponent,
    LoadingComponent,
    HomepageComponent,
    LoginComponent, DanhMucNgonNguComponent, DanhMucQuocGiaComponent, DanhMucThanhVienComponent, DanhMucTinhThanhComponent, DanhMucTinhTrangComponent, DanhMucUngDungComponent, DanhMucXaPhuongComponent, ThanhVienComponent, ThanhVienChucNangComponent, ThanhVienKhuVucComponent, ThanhVienLichSuTruyCapComponent, ThanhVienTokenComponent, DanhMucChucNangComponent, DanhMucQuanHuyenComponent, DanhMucChauLucComponent, DanhMucToChucComponent, ToChucComponent, ToChucDetailComponent, ThanhVienDetailComponent, ThanhVienThongTinComponent, DanhMucDonViTinhComponent, DanhMucLoaiHinhComponent, DanhMucNganhNgheComponent, DanhMucNhomSanPhamComponent, DanhMucPhuongThucSanXuatComponent, DanhMucQuyTrinhComponent, DanhMucSanPhamComponent, DanhMucTapTinDinhKemPhanLoaiComponent, LoHangComponent, LoHangDetailComponent, HuongDanComponent, GioiThieuComponent, LoHangMapComponent, TemPhieuLichSuTruyCapDetailComponent, TemPhieuKhoiTaoComponent, TemPhieuKhoiTaoDetailComponent, ToChucTemPhieuComponent, TemPhieuKhoiTaoDetailToChucComponent, DonViToChucDetailComponent, DonViLoHangComponent, DonViToChucKhoTemComponent, DonViToChucTemPhieuKhoiTaoComponent, DonViToChucTemPhieuKhoiTaoChiTietComponent, DonViToChucTemPhieuKhoiTaoChiTietActiveComponent, DonViLoHangDetailComponent, TemPhieuKhoiTao001Component, ThanhVienThongBaoComponent, LoHangInfoComponent, DonViLoHangInfoComponent, ThanhVienChucNangDetailComponent, DanhMucPhuongThucMuaBanComponent, DanhMucTinhThanhToaDoComponent, ThoiTietComponent, DanhMucTinhThanhToaDoDetailComponent, UploadComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    FormsModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    GoogleMapsModule,
    ChartsModule,
    CKEditorModule,
  ],
  providers: [
    CookieService,
    NotificationService,
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
